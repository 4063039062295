<template>
  <div>
    <!-- if this is active route (e.g. /users) -->
    <template v-if="isCurrentRoute">
      <h2>Rechnungsübersicht</h2>

      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <!-- <b-button variant="outline-primary" @click="navigate('Users.Addresses.Add')"><b-icon icon="plus"/> {{ $t('views.users.addresses.button.addaddress') }}</b-button> -->
        </b-col>
      </b-row>

     <b-table striped hover :items="this.invoices" :fields="fields">
        <template v-slot:cell(total)="data">
            {{ formatMoney(data.item.total)}}
        </template>
        <template v-slot:cell(validFrom)="data">
            {{ new Date(data.item.validFrom).toLocaleDateString('de-CH', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}
        </template>
         <template v-slot:cell(validTo)="data">
            {{ new Date(data.item.validTo).toLocaleDateString('de-CH', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}
        </template>
          </b-table>
    </template>

    <!-- if subroute is active route (e.g. /accountingtasks/detail) -->
    <section v-else>
      <router-view/>
    </section>
  </div>
</template>

<script>
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import http from '@/$plugins/http'

const ENTITY_KEY = 'invoices'
const RELATION_KEY = 'accountingtasks'

export default {
  name: 'Projects.Invoices',
  mixins: [MixinEntityBase],
  components: {
    // EntityEntryTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      loading: false,
      invoices: {}, // Store accounting task names by ID
      fields: [
        { key: 'invoiceNumber', label: 'Rechnungsnummer', sortable: true },
        { key: 'name', label: 'Titel', sortable: true },
        { key: 'total', label: 'Total (in CHF)', class: 'text-right', sortable: true },
        { key: 'validFrom', label: 'Rechnungsdatum', sortable: true },
        { key: 'validTo', label: 'Zu bezahlen bis', sortable: true },
        {
          key: 'totalReceivedPayments',
          label: 'Zahlungsstatus',
          class: 'text-right',
          sortable: true,
          formatter: (value, key, item) => {
            const received = parseFloat(item.totalReceivedPayments) || 0
            const remaining = parseFloat(item.totalRemainingPayments) || 0
            if (remaining === 0) {
              return 'Bezahlt'
            }
            if (received === 0) {
              return 'Offen'
            }

            return 'Teilweise bezahlt'
          }
        }
      ]
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    }
  },
  methods: {
    formatNumber (number) {
      if (number === null || number === undefined) return
      return number.toLocaleString('de-CH', { minimumFractionDigits: 2 })
    },
    formatMoney (amount) {
      return parseFloat(amount).toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF'
      })
    },
    async loadInvoices () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.invoices}/fromproject/${this.projectId}`)
        this.invoices = response.data
        this.invoices.sort((a, b) => new Date(b.validFrom) - new Date(a.validFrom)) // Sort invoices by date in descending order
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    const urlParts = window.location.pathname.split('/')
    const projectId = urlParts[urlParts.length - 2] // Extract the second-to-last part from the URL path
    this.projectId = projectId
    this.loadInvoices()
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
